<template>
  <page-container
    is-show-breadcrumbs
    style="padding-bottom: 24px"
    class="custom-el-style"
  >
    <el-main>
      <el-form label-width="100px" class="demo-ruleForm">
        <el-form-item
          v-for="(item, index) in formData"
          :key="index"
          :label="'首页网址' + (index + 1)"
          :label-width="labelWidth"
        >
          <el-input v-model="formData[index]" />
          <i
            class="iconfont icon-a-zu21882"
            v-if="index"
            @click="handleDelete(index)"
          />
          <div class="tip">请输入http或者https协议的域名，否则将影响挂件展示效果</div>
        </el-form-item>
        <el-form-item :label-width="labelWidth">
          <i class="iconfont icon-a-zu21881" @click="handleAdd" />
        </el-form-item>
        <el-form-item :label-width="labelWidth">
          <el-button type="primary" @click="submit">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </page-container>
</template>
<script>
import PageContainer from "@/components/common/PageContainer.vue";
import { webSiteApi } from "@/services/api";
export default {
  components: {
    PageContainer
  },
  data() {
    return {
      formData: [],
      labelWidth: "120px"
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    async getData() {
      let { code, data } = await webSiteApi.getUrlConfig();
      console.log(data);
      if (data.length == 0) {
        this.formData = [""];
      } else {
        this.formData = data;
      }
    },
    handleAdd() {
      this.formData.push("");
      console.log("this.formData", this.formData);
    },
    handleDelete(index) {
      this.formData.splice(index, 1);
    },
    async submit() {
      let bool = this.formData.filter(item => Boolean(item) == true);
      let { code } = await webSiteApi.setUrlConfig({ value: bool });
      if (code == 200) {
        this.$message.success("设置成功");
        this.getData();
        this.$router.push("/wabpage/site");
      }
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.el-header {
  display: flex;
  align-items: center;
}
// /deep/.el-input .el-input__inner {
//   width: 50% !important;
// }
/deep/.el-radio {
  margin-right: 100px;
}
.icon-a-zu21881 {
  font-size: 20px;
}
.icon-a-zu21882 {
  margin-left: 20px;
  font-size: 20px;
}
.iconfont {
  cursor: pointer;
}
.tip{
  display: block;
  font-size: 12px;
  line-height: 24px;
  color: #e6a23c;
}
</style>
